import React from "react"

import SEO from "layout/SEO"
import Layout from "layout/Layout"
import Home from "../components/StaticPages/Home/Home"

export default () => {
  return (
    <Layout>
      <SEO title="Home" />
      <Home />
    </Layout>
  )
}
