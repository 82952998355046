import React, { Fragment, useContext, useEffect } from "react"

import HomeBanner from "./HomeBanner"
import { AppContext } from "../../../context/AppContext"

const Home = () => {
  const { dispatch } = useContext(AppContext)

  useEffect(() => {
    dispatch({ type: "RESET_STATE" })
  })

  return (
    <Fragment>
      <HomeBanner />
    </Fragment>
  )
}

export default Home
