import { useStaticQuery, graphql, Link } from "gatsby"
import React, { Fragment, useEffect } from "react"
import classNames from "classnames"
import Img from "gatsby-image"

import Container from "../../Layout/Container"

import { ProgramName, Brand } from "../../Elements/Brand"
import { animate } from "../../../services/animations"
import styles from "../utils/staticPages.modules.scss"

const HomeBanner = () => {
  const data = useStaticQuery(graphql`
    {
      stethoscope: file(relativePath: { eq: "stethoscope.jpg" }) {
        childImageSharp {
          fluid(
            maxWidth: 2000
            duotone: { highlight: "#ffffff", shadow: "#3d8b8b" }
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      kumustaDokLogo: file(relativePath: { eq: "icons/kumusta_dok_logo.png" }) {
        childImageSharp {
          fixed(width: 250) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  const stethoscope = data.stethoscope.childImageSharp.fluid

  useEffect(() => {
    animate({ target: ".fade-to-right", x: -50 })
  }, [])

  return (
    <Fragment>
      <section className="hero is-medium">
        <div
          className="hero-body"
          style={{
            backgroundImage: `url(${stethoscope.src})`,
            backgroundPosition: "cover",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <Container isCentered desktop={10} fullhd={8}>
            <div className="container fade-to-right">
              <Img fixed={data.kumustaDokLogo.childImageSharp.fixed} />
              <h2 className="mt-0">
                Welcome to the <br className="is-hidden-mobile" />
                <ProgramName />
              </h2>
              <h5 className="subtitle">
                The <ProgramName /> is a diagnostic support and adherence{" "}
                {/* <br className="is-hidden-mobile" /> */}
                program from <Brand type="company" />.
              </h5>
              <div className="mt-2">
                <Link
                  to="/doctor"
                  className={classNames(
                    "button is-primary is-medium",
                    styles["home__buttonIsFullwidth"]
                  )}
                >
                  Enroll as Doctor
                </Link>
                <p className="mt-1">
                  Become a partner doctor so your patients
                  {/*<br className="is-hidden-mobile" />*/} can avail of diagnostic
                  support.
                </p>
              </div>
              <div className="mt-2">
                <Link
                  to="/patient"
                  className={classNames(
                    "button mr-1 is-primary is-medium",
                    styles["home__buttonIsFullwidth"]
                  )}
                >
                  Apply for Diagnostic Support
                </Link>
                <p className="mt-1">
                  Patients of enrolled doctors
                  can{/* can <br className="is-hidden-mobile" /> */} apply for diagnostic
                  support from our partner laboratories.
                </p>
              </div>
            </div>
          </Container>
        </div>
      </section>
    </Fragment>
  )
}

export default HomeBanner
